
import Axios from "axios";
import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import Notification from "../../../common-components/Notification.vue";
import ProcessorLoanList from "../../../common-components/loan-list/ProcessorLoanList.vue";

@Component({
  components: {
    Notification,
    ProcessorLoanList,
  },
})
export default class DashBoard extends Vue {
  /* eslint-disable */
  public processorName: any = null;
  public totalLength = 0;

  public async getProcessorType() {
    this.$store.state.tmpcoLoader = true;
    let res = await Axios.get(
      BASE_API_URL + "processor/dashboard/getProcessorType",
      {
        headers: authHeader(),
      }
    );
    this.processorName = res.data.name;
    this.$store.state.tmpcoLoader = false;
  }

  public getTotalLoanLength(data) {
    this.totalLength = data;
  }

  async mounted() {
    await this.getProcessorType();
  }
  /* eslint-disable */
}
